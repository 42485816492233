// Object - page - top - news

// ============================================
// NEWS
// ============================================
.news-box {
    transition: 0.4s;
}
body:not(.fp-viewing-0) .news-box {
    opacity: 0;
    z-index: -10;
    //display: none;
}

.news-box {
    width: 100%;
    max-width: _vw(850, 1366);
    position: absolute;
    left: 0;
    bottom: 12%;
    z-index: 10;
    background: $color-brand;
    background: linear-gradient(135deg, $color-brand 0%, $color-brand-accent 100%);
    display: flex;
    align-items: center;
    padding: 0 0 0 _vw(36, 1366);
    opacity: 1;
    //z-index: 999;
    @include _mq-up(xxl) {
        max-width: 850px;
        padding: 0 0 0 36px;
    }
    @include _mq-down(sm) {
        position: fixed;
        display: block;
        bottom: 0;
        padding: 0;
        max-width: 100%;
        height: _vw(85, 375);
        /*&:before {
            content: "";
            display: block;
            width: 100%;
            height: 30px;
            background: rgba(0, 0, 0, .3);
        }*/
    }
}

.news-head {
    color: #fff;
    font-weight: bold;
    font-size: _vw(16, 1366);
    letter-spacing: 0.2em;
    @include _mq-up(xxl) {
        font-size: 16px;
    }
    @include _mq-down(sm) {
        font-size: _vw(16, 375);
        background: rgba(0,0,0,.3);
        height: _vw(30, 375);
        padding: _vw(7, 375) 0 _vw(7, 375) _vw(15, 375);;
    }
}

.news-list-box {
    width: _vw(70, 1366);
    height: _vw(70, 1366);
    flex-shrink: 0;
    background-color: $color-brand;
    //position: relative;
    position: absolute;
    right: 0;
    @include _mq-up(xxl) {
        width: 70px;
        height: 70px;
    }
    @include _mq-down(sm) {
        width: _vw(70, 375);
        height: _vw(30, 375);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
    }
    @include _hover {
        background-color: lighten($color-brand-accent, 7%);
    }
    .news-trigger {
        height: auto;
        display: block;
        width: _vw(20, 1366);
        margin: _vw(18, 1366) auto 0;
        img {
            display: block;
        }
        @include _mq-up(xxl) {
            width: 20px;
            margin: 18px auto 0;
        }
        @include _mq-down(sm) {
            margin: 0;
            width: _vw(16, 375);
            height: _vw(12, 375);
            position: absolute;
            right: _vw(10, 375);
        }
    }
    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: _vw(10, 1366);
        @include _mq-up(xxl) {
            font-size: 10px;
        }
        @include _mq-down(sm) {
            font-size: _vw(10, 375);
        }
        span {
            position: absolute;
            right: 0;
            bottom: _vw(17, 1366);
            left: 0;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            letter-spacing: 0.1em;
            padding-left: 0.1em;
            @include _mq-up(xxl) {
                bottom: 17px;
            }
            @include _mq-down(sm) {
                top: 0;
                bottom: 0;
                left: _vw(10, 375);
                right: auto;
                margin: auto 0;
                align-items: center;
            }
        }
    }
}

#news {
    .news-swiper-wrap {
        height: _vw(70, 1366);
        width: _vw(663, 1366);
        position: relative;
        margin: 0 _vw(10, 1366);
        z-index: 0;
        @include _mq-up(xxl) {
            height: 70px;
            width: 663px;
            margin: 0 10px;
        }
        @include _mq-down(sm) {
            width: 96%;
            margin: 0 2%;
            height: _vw(55, 375);
        }
    }
    .news-swiper {
        overflow: hidden;
        margin: 0 _vw(42, 1366);
        height: 100% !important;
        @include _mq-up(xxl) {
            margin: 0 42px;
        }
        @include _mq-down(sm) {
            margin: 0 30px;
        }
    }
    .swiper-slide {
        a {
            display: block;
            height: _vw(70, 1366);
            display: flex;
            align-items: center;
            color: #fff;
            padding: 0 _vw(5, 1366);
            font-size: _vw(14, 1366);
            @include _mq-up(xxl) {
                height: 70px;
                font-size: 14px;
                padding: 0 5px;
            }
            @include _mq-down(sm) {
                font-size: _vw(13, 375);
                padding: 0 _vw(5, 375);
                display: flex;
                align-items: center;
                height: _vw(55, 375);
            }
        }
        p {
            line-height: 1.4;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-height: _vw(38, 1366);
            overflow: hidden;
            @include _mq-up(xxl) {
                max-height: 38px;
            }
            @include _mq-down(sm) {
                max-height: _vw(36, 375);
                font-size: _vw(12, 375);
            }
        }
        time {
            letter-spacing: 0.05em;
            margin-right: _vw(30, 1366);
            @include _mq-up(xxl) {
                margin-right: 30px;
            }
            @include _mq-down(sm) {
                margin: 0 _vw(15, 375) 0 0;
                font-size: _vw(12, 375);
            }
        }
    }
    .swiper-button-prev.news {
        left: 0;
        background: url(../img/common/arrow-l-white.svg) center center/_vw(7, 1366) _vw(11, 1366) no-repeat;
        @include _mq-down(sm) {
            background-size: _vw(7, 375) _vw(11, 375);
        }
    }
    .swiper-button-next.news {
        right: 0;
        background: url(../img/common/arrow-r-white.svg) center center/_vw(7, 1366) _vw(11, 1366) no-repeat;
        @include _mq-down(sm) {
            background-size: _vw(7, 375) _vw(11, 375);
        }
    }
}

.fp-viewing-1 {
    .news-box {

    }
}

