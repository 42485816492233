// Object - page - top - topics

// ============================================
// TOPICS
// ============================================

.topics-box {
    width: _vw(380, 1366);
    position: fixed;
    top: 20%;
    left: _vw(-340, 1366);
    background: transparent;
    //padding: _vw(28, 1366) _vw(48, 1366) _vw(28, 1366) _vw(28, 1366);
    border-radius: _vw(10, 1366);
    //border: 1px solid $color-brand-topics;
    //overflow: hidden;
    z-index: 20;
    transition: left .3s ease, top .3s ease;
    @include _mq-up(xxl) {
        width: 380px;
        left: -340px;
        border-radius: 10px;
        //padding: 28px 48px 18px 28px;
    }
    @include _mq-down(sm) {
        width: 250px;
        top: -238px; //記事3つ
        //top: -75px; //記事1つ
        left: -10px;
        //border-radius: 10px;
        //padding: 30px 18px 35px 30px;
    }
    .inr {
        width: _vw(340, 1366);
        padding: _vw(28, 1366) 0 _vw(28, 1366) _vw(28, 1366);
        background-color: #fff;
        border-color: $color-brand-topics;
        border-style: solid;
        border-width: 1px 0 1px 1px;
        @include _mq-up(xxl) {
            width: 340px;
            padding: 28px 0 18px 28px;
        }
        @include _mq-down(sm) {
            width: 250px;
            padding: 30px 18px 0 30px;
            border-width: 1px;
            border-radius: 0 0 10px 0;
        }
        ul {
            padding-right: _vw(18, 1366);
            @include _mq-up(xxl) {
                padding-right: 18px;
            }
            @include _mq-down(sm) {
                padding-right: 0;
                padding-bottom: 10px;
            }
        }
    }
    .head {
        margin-bottom: _vw(5, 1366);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: _vw(16, 1366);
        @include _mq-up(xxl) {
            margin-bottom: 5px;
            margin-right: 16px;
        }
        @include _mq-down(sm) {
            margin-bottom: 5px;
            margin-right: 0px;
        }
        h4 {
            color: $color-brand;
            font-size: _vw(25, 1366);
            font-weight: bold;
            letter-spacing: 0.1em;
            @include _mq-up(xxl) {
                font-size: 25px;
            }
            @include _mq-down(sm) {
                font-size: 20px;
                img {
                  width: 100%;
                }
            }
        }
        a.list-link {
            display: block;
            background: $color-brand;
            color: #fff;
            height: 22px;
            width: 66px;
            border-radius: 11px;
            text-align: center;
            padding: 5px;
            font-size: 12px;
            letter-spacing: 0.1em;
        }
    }
    .list {
        padding: _vw(7, 1366) 0 _vw(9, 1366);
        border-bottom: 1px solid #DCDCDC;
        line-height: 1.4;
        &:last-child {
            border-bottom: 0;
        }
        @include _mq-up(xxl) {
            padding: 7px 0 9px;
        }
        @include _mq-down(sm) {
            padding: 9px 0;
        }
        time, p, a {
            display: block;
            font-size: _vw(14, 1366);
            @include _mq-up(xxl) {
                font-size: 14px;
            }
            @include _mq-down(sm) {
                font-size: 13px;
            }
        }
        p {
            color: $color-brand;
            height: auto;
            max-height: _vw(40, 1366);
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            @include _mq-up(xxl) {
                max-height: 50px;
            }
            @include _mq-down(sm) {
                font-size: 14px;
                height: 40px;
                max-height: 40px;
            }
        }
    }
    .topics-box-close {
        display: flex;
        display: -ms-flexbox;
        align-items: center;
        justify-content: center;
        width: _vw(40, 1366);
        background-color: $color-brand-topics;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        border-radius: 0 _vw(10, 1366) _vw(10, 1366) 0;
        writing-mode: vertical-rl;
        font-size: _vw(18, 1366);
        font-weight: 600;
        color: #fff;
        letter-spacing: 0.15em;
        margin: 0;
        border-color: $color-brand-topics;
        border-style: solid;
        border-width: 1px 1px 1px 0;
        @include _mq-up(xxl) {
            width: 40px;
            font-size: 18px;
            border-radius: 0 10px 10px 0;
        }
        @include _mq-down(sm) {
            width: 135px;
            font-size: 15px;
            writing-mode: horizontal-tb;
            border-radius: 0 0 10px 10px;
            height: 30px;
            top: auto;
            bottom: -30px;
            border-width: 0px 1px 1px 1px;
            left: 0;
            right: auto;
            letter-spacing: 0.05em;
        }
        @include _hover {
            background-color: $color-brand;
        }
        .topics-btn {
            position: relative;
            padding-bottom: _vw(12, 1366);
            &::after {
                content: '';
                position: absolute;
                right: _vw(2, 1366);
                left: 0;
                margin: 0 auto;
                bottom: _vw(-12, 1366);
                width: _vw(6, 1366);
                height: _vw(12, 1366);
                //background: url("../../assets/img/common/arrow-l-white.svg") no-repeat;
            }
            &.close {
                display: none;
                &::after {
                    background: url("../../assets/img/common/arrow-l-white.svg") no-repeat;
                }
            }
            &.open {
                &::after {
                    background: url("../../assets/img/common/arrow-r-white.svg") no-repeat;
                }
            }
            @include _mq-up(xxl) {
                padding-bottom: 12px;
                &::after {
                    right: 2px;
                    bottom: -12px;
                    width: 6px;
                    height: 12px;
                }
            }
            @include _mq-down(sm) {
                padding-bottom: 0;
                padding-left: 20px;
                &::after {
                    left: 0;
                    right: auto;
                    bottom: auto;
                    top: 4px;
                    margin: auto 0;
                    width: 12px;
                    height: 6px;
                }
                &.close {
                    display: none;
                    &::after {
                        background: url("../../assets/img/common/arrow-t-white.svg") no-repeat;
                    }
                }
                &.open {
                    &::after {
                        background: url("../../assets/img/common/arrow-b-white.svg") no-repeat;
                    }
                }
            }
        }
    }
    &.active {
        left: _vw(-10, 1366);
        @include _mq-up(xxl) {
            left: -10px;
        }
        @include _mq-down(sm) {
            left: -10px;
            top: -10px;
            margin-top: 60px;
        }
        .topics-box-close {
            .open {
                display: none;
            }
            .close {
                display: block;
            }
        }
    }
}



