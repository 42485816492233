// Object - swiper

#fullpage {

.swiper {
    //height: 100%;
    //background: #fff;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    //width: 100%;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
  //height: calc(var(--vh, 1vh) * 100);
}

.swiper-slide {
  //color: #ffffff;
  width: 100%;
  height: 100%;
  //height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin: 0 0 0 10px;
  //background: #c7c7c7;
  background: #3794c4;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: #8fd3f5;
}
.swiper-pagination-bullet:first-child {
  margin: 0 !important;
}

.swiper-slide:nth-child(3n + 1) {
  /*1、4、7、3n+1枚目の背景色 */
  //background-color: #ccc;
}

.swiper-slide:nth-child(3n + 2) {
  /*2、5、8、3n+2枚目の背景色 */
  //background-color: #ddd;
}

.swiper-slide:nth-child(3n + 3) {
  /*3、6、9、3n+3枚目の背景色 */
  //background-color: #eee;
}

.swiper-button-next, .swiper-button-prev{
    position: absolute;
    top: auto;
    bottom: 30px;
    width: 55px;
    height: auto;
    margin-top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    cursor: pointer;
    //background-size: 27px 44px;
    //background-position: center;
    //background-repeat: no-repeat;
}

.swiper-button-next {
    background: url("../../assets/img/common/arrow-r-black.svg") right center / 7px 11px no-repeat;
    left: 210px;
    padding-right: 20px;
    line-height: 1;
    text-align: right;
    &::after {
        content:'次へ';
        line-height: 1;
    }
}

.swiper-button-prev {
    background: url("../../assets/img/common/arrow-l-black.svg") left center / 7px 11px no-repeat;
    right: 210px;
    padding-left: 20px;
    &::after {
        content:'前へ';
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 30px;
}

.sec-Admission {
    .swiper-button-next {
        color: #fff;
        background: url("../../assets/img/common/arrow-r-white.svg") right center / 7px 11px no-repeat;
        opacity: 0.7;
    }
    .swiper-button-prev {
        color: #fff;
        background: url("../../assets/img/common/arrow-l-white.svg") left center / 7px 11px no-repeat;
        opacity: 0.7;
    }
}

}

/*OS判定 PCのみ*/
body.js_isPc {
    #fullpage {
        .fp-tableCell, .swiper-slide {
            height: 100vh !important;
        }
    }
}
body.js_isTablet {
    .mainvisual {
        height: auto !important;
    }
    #fullpage {
        .section.fp-section {
            height: 100vh !important;
            .swiper-slide {
                height: 100vh !important;
            }
        }
        /*
        .fp-tableCell, .swiper-slide {
            height: 100vh !important;
        }
        .fp-section.fp-table, .fp-slide.fp-table {
            height: 100vh !important;
        }
        */
    }
}
