// Object - fullpage

#fullpage {
    //height: calc(var(--vh, 1vh) * 100);
    z-index: 1
}

body {
    #fp-nav {
        /*position: fixed;*/
        margin-top: 0 !important;
        left: _vw(25);
        top: 60% !important;
        ul li a span {
            height: 12px;
            width: 12px;
            margin: -6px 0 0 -6px;
            border-radius: 100%;
            background: #333;
            opacity: 0.2;
        }
        ul li:hover a span {
            height: 12px;
            width: 12px;
            margin: -6px 0 0 -6px;
            border-radius: 100%;
        }
        ul li a.active span {
            background: #333;
            opacity: 0.7;
        }
        ul li {
            margin: 10px 0 0 0;
        }
        ul li:first-child {
            margin: 0 !important;
        }
        @include _mq-down(sm) {
            top: 50% !important;
            left: _vw(15, 375);
        }
    }
}


