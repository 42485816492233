@charset "UTF-8";
/*normal*/
#fullpage {
  z-index: 1; }

body #fp-nav {
  /*position: fixed;*/
  margin-top: 0 !important;
  left: 1.83016vw;
  top: 60% !important; }
  body #fp-nav ul li a span {
    height: 12px;
    width: 12px;
    margin: -6px 0 0 -6px;
    border-radius: 100%;
    background: #333;
    opacity: 0.2; }
  body #fp-nav ul li:hover a span {
    height: 12px;
    width: 12px;
    margin: -6px 0 0 -6px;
    border-radius: 100%; }
  body #fp-nav ul li a.active span {
    background: #333;
    opacity: 0.7; }
  body #fp-nav ul li {
    margin: 10px 0 0 0; }
  body #fp-nav ul li:first-child {
    margin: 0 !important; }
  @media screen and (max-width: 499px) {
    body #fp-nav {
      top: 50% !important;
      left: 4vw; } }

.news-box {
  transition: 0.4s; }

body:not(.fp-viewing-0) .news-box {
  opacity: 0;
  z-index: -10; }

.news-box {
  width: 100%;
  max-width: 62.22548vw;
  position: absolute;
  left: 0;
  bottom: 12%;
  z-index: 10;
  background: #036EB8;
  background: linear-gradient(135deg, #036EB8 0%, #004182 100%);
  display: flex;
  align-items: center;
  padding: 0 0 0 2.63543vw;
  opacity: 1; }
  @media screen and (min-width: 1366px) {
    .news-box {
      max-width: 850px;
      padding: 0 0 0 36px; } }
  @media screen and (max-width: 499px) {
    .news-box {
      position: fixed;
      display: block;
      bottom: 0;
      padding: 0;
      max-width: 100%;
      height: 22.66667vw;
      /*&:before {
            content: "";
            display: block;
            width: 100%;
            height: 30px;
            background: rgba(0, 0, 0, .3);
        }*/ } }

.news-head {
  color: #fff;
  font-weight: bold;
  font-size: 1.1713vw;
  letter-spacing: 0.2em; }
  @media screen and (min-width: 1366px) {
    .news-head {
      font-size: 16px; } }
  @media screen and (max-width: 499px) {
    .news-head {
      font-size: 4.26667vw;
      background: rgba(0, 0, 0, 0.3);
      height: 8vw;
      padding: 1.86667vw 0 1.86667vw 4vw; } }

.news-list-box {
  width: 5.12445vw;
  height: 5.12445vw;
  flex-shrink: 0;
  background-color: #036EB8;
  position: absolute;
  right: 0; }
  @media screen and (min-width: 1366px) {
    .news-list-box {
      width: 70px;
      height: 70px; } }
  @media screen and (max-width: 499px) {
    .news-list-box {
      width: 18.66667vw;
      height: 8vw;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0; } }
  @media (hover: hover) {
    .news-list-box:hover {
      background-color: #0053a6; } }
  .news-list-box .news-trigger {
    height: auto;
    display: block;
    width: 1.46413vw;
    margin: 1.31772vw auto 0; }
    .news-list-box .news-trigger img {
      display: block; }
    @media screen and (min-width: 1366px) {
      .news-list-box .news-trigger {
        width: 20px;
        margin: 18px auto 0; } }
    @media screen and (max-width: 499px) {
      .news-list-box .news-trigger {
        margin: 0;
        width: 4.26667vw;
        height: 3.2vw;
        position: absolute;
        right: 2.66667vw; } }
  .news-list-box a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 0.73206vw; }
    @media screen and (min-width: 1366px) {
      .news-list-box a {
        font-size: 10px; } }
    @media screen and (max-width: 499px) {
      .news-list-box a {
        font-size: 2.66667vw; } }
    .news-list-box a span {
      position: absolute;
      right: 0;
      bottom: 1.24451vw;
      left: 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      letter-spacing: 0.1em;
      padding-left: 0.1em; }
      @media screen and (min-width: 1366px) {
        .news-list-box a span {
          bottom: 17px; } }
      @media screen and (max-width: 499px) {
        .news-list-box a span {
          top: 0;
          bottom: 0;
          left: 2.66667vw;
          right: auto;
          margin: auto 0;
          align-items: center; } }

#news .news-swiper-wrap {
  height: 5.12445vw;
  width: 48.53587vw;
  position: relative;
  margin: 0 0.73206vw;
  z-index: 0; }
  @media screen and (min-width: 1366px) {
    #news .news-swiper-wrap {
      height: 70px;
      width: 663px;
      margin: 0 10px; } }
  @media screen and (max-width: 499px) {
    #news .news-swiper-wrap {
      width: 96%;
      margin: 0 2%;
      height: 14.66667vw; } }

#news .news-swiper {
  overflow: hidden;
  margin: 0 3.07467vw;
  height: 100% !important; }
  @media screen and (min-width: 1366px) {
    #news .news-swiper {
      margin: 0 42px; } }
  @media screen and (max-width: 499px) {
    #news .news-swiper {
      margin: 0 30px; } }

#news .swiper-slide a {
  display: block;
  height: 5.12445vw;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 0.36603vw;
  font-size: 1.02489vw; }
  @media screen and (min-width: 1366px) {
    #news .swiper-slide a {
      height: 70px;
      font-size: 14px;
      padding: 0 5px; } }
  @media screen and (max-width: 499px) {
    #news .swiper-slide a {
      font-size: 3.46667vw;
      padding: 0 1.33333vw;
      display: flex;
      align-items: center;
      height: 14.66667vw; } }

#news .swiper-slide p {
  line-height: 1.4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.78184vw;
  overflow: hidden; }
  @media screen and (min-width: 1366px) {
    #news .swiper-slide p {
      max-height: 38px; } }
  @media screen and (max-width: 499px) {
    #news .swiper-slide p {
      max-height: 9.6vw;
      font-size: 3.2vw; } }

#news .swiper-slide time {
  letter-spacing: 0.05em;
  margin-right: 2.19619vw; }
  @media screen and (min-width: 1366px) {
    #news .swiper-slide time {
      margin-right: 30px; } }
  @media screen and (max-width: 499px) {
    #news .swiper-slide time {
      margin: 0 4vw 0 0;
      font-size: 3.2vw; } }

#news .swiper-button-prev.news {
  left: 0;
  background: url(../img/common/arrow-l-white.svg) center center/0.51245vw 0.80527vw no-repeat; }
  @media screen and (max-width: 499px) {
    #news .swiper-button-prev.news {
      background-size: 1.86667vw 2.93333vw; } }

#news .swiper-button-next.news {
  right: 0;
  background: url(../img/common/arrow-r-white.svg) center center/0.51245vw 0.80527vw no-repeat; }
  @media screen and (max-width: 499px) {
    #news .swiper-button-next.news {
      background-size: 1.86667vw 2.93333vw; } }

#fullpage .swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1; }

#fullpage .swiper-wrapper {
  width: 100%;
  height: 100%; }

#fullpage .swiper-slide {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }

#fullpage .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin: 0 0 0 10px;
  background: #3794c4;
  opacity: 1; }

#fullpage .swiper-pagination-bullet-active {
  background: #8fd3f5; }

#fullpage .swiper-pagination-bullet:first-child {
  margin: 0 !important; }

#fullpage .swiper-slide:nth-child(3n + 1) {
  /*1、4、7、3n+1枚目の背景色 */ }

#fullpage .swiper-slide:nth-child(3n + 2) {
  /*2、5、8、3n+2枚目の背景色 */ }

#fullpage .swiper-slide:nth-child(3n + 3) {
  /*3、6、9、3n+3枚目の背景色 */ }

#fullpage .swiper-button-next, #fullpage .swiper-button-prev {
  position: absolute;
  top: auto;
  bottom: 30px;
  width: 55px;
  height: auto;
  margin-top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  cursor: pointer; }

#fullpage .swiper-button-next {
  background: url("../../assets/img/common/arrow-r-black.svg") right center/7px 11px no-repeat;
  left: 210px;
  padding-right: 20px;
  line-height: 1;
  text-align: right; }
  #fullpage .swiper-button-next::after {
    content: '次へ';
    line-height: 1; }

#fullpage .swiper-button-prev {
  background: url("../../assets/img/common/arrow-l-black.svg") left center/7px 11px no-repeat;
  right: 210px;
  padding-left: 20px; }
  #fullpage .swiper-button-prev::after {
    content: '前へ'; }

#fullpage .swiper-container-horizontal > .swiper-pagination-bullets, #fullpage .swiper-pagination-custom, #fullpage .swiper-pagination-fraction {
  bottom: 30px; }

#fullpage .sec-Admission .swiper-button-next {
  color: #fff;
  background: url("../../assets/img/common/arrow-r-white.svg") right center/7px 11px no-repeat;
  opacity: 0.7; }

#fullpage .sec-Admission .swiper-button-prev {
  color: #fff;
  background: url("../../assets/img/common/arrow-l-white.svg") left center/7px 11px no-repeat;
  opacity: 0.7; }

/*OS判定 PCのみ*/
body.js_isPc #fullpage .fp-tableCell, body.js_isPc #fullpage .swiper-slide {
  height: 100vh !important; }

body.js_isTablet .mainvisual {
  height: auto !important; }

body.js_isTablet #fullpage {
  /*
        .fp-tableCell, .swiper-slide {
            height: 100vh !important;
        }
        .fp-section.fp-table, .fp-slide.fp-table {
            height: 100vh !important;
        }
        */ }
  body.js_isTablet #fullpage .section.fp-section {
    height: 100vh !important; }
    body.js_isTablet #fullpage .section.fp-section .swiper-slide {
      height: 100vh !important; }

.topics-box {
  width: 27.81845vw;
  position: fixed;
  top: 20%;
  left: -24.89019vw;
  background: transparent;
  border-radius: 0.73206vw;
  z-index: 20;
  transition: left .3s ease, top .3s ease; }
  @media screen and (min-width: 1366px) {
    .topics-box {
      width: 380px;
      left: -340px;
      border-radius: 10px; } }
  @media screen and (max-width: 499px) {
    .topics-box {
      width: 250px;
      top: -238px;
      left: -10px; } }
  .topics-box .inr {
    width: 24.89019vw;
    padding: 2.04978vw 0 2.04978vw 2.04978vw;
    background-color: #fff;
    border-color: #358AC6;
    border-style: solid;
    border-width: 1px 0 1px 1px; }
    @media screen and (min-width: 1366px) {
      .topics-box .inr {
        width: 340px;
        padding: 28px 0 18px 28px; } }
    @media screen and (max-width: 499px) {
      .topics-box .inr {
        width: 250px;
        padding: 30px 18px 0 30px;
        border-width: 1px;
        border-radius: 0 0 10px 0; } }
    .topics-box .inr ul {
      padding-right: 1.31772vw; }
      @media screen and (min-width: 1366px) {
        .topics-box .inr ul {
          padding-right: 18px; } }
      @media screen and (max-width: 499px) {
        .topics-box .inr ul {
          padding-right: 0;
          padding-bottom: 10px; } }
  .topics-box .head {
    margin-bottom: 0.36603vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1.1713vw; }
    @media screen and (min-width: 1366px) {
      .topics-box .head {
        margin-bottom: 5px;
        margin-right: 16px; } }
    @media screen and (max-width: 499px) {
      .topics-box .head {
        margin-bottom: 5px;
        margin-right: 0px; } }
    .topics-box .head h4 {
      color: #036EB8;
      font-size: 1.83016vw;
      font-weight: bold;
      letter-spacing: 0.1em; }
      @media screen and (min-width: 1366px) {
        .topics-box .head h4 {
          font-size: 25px; } }
      @media screen and (max-width: 499px) {
        .topics-box .head h4 {
          font-size: 20px; }
          .topics-box .head h4 img {
            width: 100%; } }
    .topics-box .head a.list-link {
      display: block;
      background: #036EB8;
      color: #fff;
      height: 22px;
      width: 66px;
      border-radius: 11px;
      text-align: center;
      padding: 5px;
      font-size: 12px;
      letter-spacing: 0.1em; }
  .topics-box .list {
    padding: 0.51245vw 0 0.65886vw;
    border-bottom: 1px solid #DCDCDC;
    line-height: 1.4; }
    .topics-box .list:last-child {
      border-bottom: 0; }
    @media screen and (min-width: 1366px) {
      .topics-box .list {
        padding: 7px 0 9px; } }
    @media screen and (max-width: 499px) {
      .topics-box .list {
        padding: 9px 0; } }
    .topics-box .list time, .topics-box .list p, .topics-box .list a {
      display: block;
      font-size: 1.02489vw; }
      @media screen and (min-width: 1366px) {
        .topics-box .list time, .topics-box .list p, .topics-box .list a {
          font-size: 14px; } }
      @media screen and (max-width: 499px) {
        .topics-box .list time, .topics-box .list p, .topics-box .list a {
          font-size: 13px; } }
    .topics-box .list p {
      color: #036EB8;
      height: auto;
      max-height: 2.92826vw;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; }
      @media screen and (min-width: 1366px) {
        .topics-box .list p {
          max-height: 50px; } }
      @media screen and (max-width: 499px) {
        .topics-box .list p {
          font-size: 14px;
          height: 40px;
          max-height: 40px; } }
  .topics-box .topics-box-close {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    width: 2.92826vw;
    background-color: #358AC6;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    border-radius: 0 0.73206vw 0.73206vw 0;
    writing-mode: vertical-rl;
    font-size: 1.31772vw;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.15em;
    margin: 0;
    border-color: #358AC6;
    border-style: solid;
    border-width: 1px 1px 1px 0; }
    @media screen and (min-width: 1366px) {
      .topics-box .topics-box-close {
        width: 40px;
        font-size: 18px;
        border-radius: 0 10px 10px 0; } }
    @media screen and (max-width: 499px) {
      .topics-box .topics-box-close {
        width: 135px;
        font-size: 15px;
        writing-mode: horizontal-tb;
        border-radius: 0 0 10px 10px;
        height: 30px;
        top: auto;
        bottom: -30px;
        border-width: 0px 1px 1px 1px;
        left: 0;
        right: auto;
        letter-spacing: 0.05em; } }
    @media (hover: hover) {
      .topics-box .topics-box-close:hover {
        background-color: #036EB8; } }
    .topics-box .topics-box-close .topics-btn {
      position: relative;
      padding-bottom: 0.87848vw; }
      .topics-box .topics-box-close .topics-btn::after {
        content: '';
        position: absolute;
        right: 0.14641vw;
        left: 0;
        margin: 0 auto;
        bottom: -0.87848vw;
        width: 0.43924vw;
        height: 0.87848vw; }
      .topics-box .topics-box-close .topics-btn.close {
        display: none; }
        .topics-box .topics-box-close .topics-btn.close::after {
          background: url("../../assets/img/common/arrow-l-white.svg") no-repeat; }
      .topics-box .topics-box-close .topics-btn.open::after {
        background: url("../../assets/img/common/arrow-r-white.svg") no-repeat; }
      @media screen and (min-width: 1366px) {
        .topics-box .topics-box-close .topics-btn {
          padding-bottom: 12px; }
          .topics-box .topics-box-close .topics-btn::after {
            right: 2px;
            bottom: -12px;
            width: 6px;
            height: 12px; } }
      @media screen and (max-width: 499px) {
        .topics-box .topics-box-close .topics-btn {
          padding-bottom: 0;
          padding-left: 20px; }
          .topics-box .topics-box-close .topics-btn::after {
            left: 0;
            right: auto;
            bottom: auto;
            top: 4px;
            margin: auto 0;
            width: 12px;
            height: 6px; }
          .topics-box .topics-box-close .topics-btn.close {
            display: none; }
            .topics-box .topics-box-close .topics-btn.close::after {
              background: url("../../assets/img/common/arrow-t-white.svg") no-repeat; }
          .topics-box .topics-box-close .topics-btn.open::after {
            background: url("../../assets/img/common/arrow-b-white.svg") no-repeat; } }
  .topics-box.active {
    left: -0.73206vw; }
    @media screen and (min-width: 1366px) {
      .topics-box.active {
        left: -10px; } }
    @media screen and (max-width: 499px) {
      .topics-box.active {
        left: -10px;
        top: -10px;
        margin-top: 60px; } }
    .topics-box.active .topics-box-close .open {
      display: none; }
    .topics-box.active .topics-box-close .close {
      display: block; }
